/* eslint-disable no-unused-vars */
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import AnchorBtn from "../../../../Components/dr.examine/Qbtn1/qbtnAnchor";
import { HiChevronDoubleLeft } from "react-icons/hi";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { RiCheckboxBlankFill } from "react-icons/ri";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Session from "../../../../Image/sheet.png";
// import SLU from "./../../../../pdf/2018-Impairment-Guidelines-SLU.pdf";
// import Impairment from "./../../../../pdf/2018-impairment-guidelines-determining-schedule-loss-use-slu.pdf";
// import HipInjury from "./../../../../pdf/Hip Injury-MTG2021.pdf";
// import HandInjury from "./../../../../pdf/Hand, Wrist & Forarm Injury-MTG2021.pdf";
// import AnkleInjury from "./../../../../pdf/Ankle & Foot Injury-MTG2021.pdf";
import "../../../_partials/widgets/advance-tables/advance.css";
import { HiMiniBars3BottomLeft } from "react-icons/hi2";
import { SiGooglesheets } from "react-icons/si";
import { IoToday } from "react-icons/io5";
import { IoIosFolder } from "react-icons/io";
import { MdContactPhone } from "react-icons/md";
import { BiSolidHelpCircle } from "react-icons/bi";
import { FaCircleUser } from "react-icons/fa6";
import { RxLapTimer } from "react-icons/rx";
import { ImSearch } from "react-icons/im";
import { MdDashboard } from "react-icons/md";
import { useLocation } from "react-router-dom";
export function MenuBar({ setIsSidebarVisible, isSidebarVisible }) {
  const history = useHistory();
  const userRole = Cookies.get("userRole");
  const location = useLocation();
  const routesToHide1 = [`/doctor-app/claimantcompletedform/`];
  const isMatchingRoute = location.pathname.startsWith(routesToHide1);
  const routesToHide2 = [`/claimant/form/`];
  const isMatchingRoute2 = location.pathname.startsWith(routesToHide2);
  const [showComponent, setShowComponent] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowComponent(true);
    }, 10);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMenuBar(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [isMenuBar, setIsMenuBar] = useState(window.innerWidth <= 992);

  useEffect(() => {
    const handleResize = () => {
      setIsTabletView(window.innerWidth < 992);
    };
  }, []);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 992);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 992);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isSidebarVisible2, setIsSidebarVisible2] = useState(false);
  const buttonSidebar2 = () => {
    setIsSidebarVisible2(!isSidebarVisible2);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsTabletView(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [isTabletView, setIsTabletView] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => {
      setIsShow(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isShow, setIsShow] = useState(window.innerWidth < 992);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  const [isActive, setIsActive] = useState(false);
  const toggleDropdown = () => {
    setIsActive(!isActive);
  };
  const [isactive, setIsactive] = useState(false);
  const toggleDropdown1 = () => {
    setIsactive(!isactive);
  };
  const [isactive1, setIsactive1] = useState(false);
  const toggleDropdown2 = () => {
    setIsactive1(!isactive1);
  };
  const [isactive2, setIsactive2] = useState(false);
  const toggleDropdown3 = () => {
    setIsactive2(!isactive2);
  };
  const [isactive3, setIsactive3] = useState(false);
  const toggleDropdown4 = () => {
    setIsactive3(!isactive3);
  };
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };
  const [isClicked1, setIsClicked1] = useState(false);

  const handleClick1 = () => {
    setIsClicked1(!isClicked1);
  };
  const [isClicked2, setIsClicked2] = useState(false);

  const handleClick2 = () => {
    setIsClicked2(!isClicked2);
  };

  const handleStartSession = () => {
    history.push("/doctor-app/frontdesk_session");
    toggleSidebar();
    buttonSidebar2();
  };
  const handleStartSession1 = () => {
    history.push("/doctor-app/Start_Day");
    toggleSidebar();
    buttonSidebar2();
  };
  const handleStartSession2 = () => {
    history.push("/doctor-app/End_Day_Sheet");
    toggleSidebar();
    buttonSidebar2();
  };

  const handleStartSession3 = () => {
    history.push("/doctor-app/Overall_Day_Sheet");
    toggleSidebar();
    buttonSidebar2();
  };
  const handleStartSession4 = () => {
    history.push("/doctor-app/from_frondesk");
    toggleSidebar();
    buttonSidebar2();
  };
  const handleStartSession5 = () => {
    history.push("/doctor-app/frontdesk_Working");
    toggleSidebar();
    buttonSidebar2();
  };
  const handleStartSession6 = () => {
    history.push("/doctor-app/frontdesk_pushedto_doctor");
    toggleSidebar();
    buttonSidebar2();
  };
  const handleStartSession7 = () => {
    history.push("/doctor-app/frontdesk_Show");
    toggleSidebar();
    buttonSidebar2();
  };
  const handleStartSession8 = () => {
    history.push("/doctor-app/frontdesk_Pending");
    toggleSidebar();
    buttonSidebar2();
  };
  const handleStartSession9 = () => {
    history.push("/doctor-app/frontdesk_cancelled");
    toggleSidebar();
    buttonSidebar2();
  };
  const handleStartSession10 = () => {
    history.push("/doctor-app/frontdesk_Noshow");
    toggleSidebar();
    buttonSidebar2();
  };
  const handleStartSession11 = () => {
    history.push("/doctor-app/frontdesk_Noshow");
    toggleSidebar();
    buttonSidebar2();
  };
  const handleStartSession12 = () => {
    history.push("/doctor-app/frontdesk_Cancelled");
    toggleSidebar();
    buttonSidebar2();
  };
  const handleStartSession13 = () => {
    history.push("/doctor-app/search_session");
    toggleSidebar();
    buttonSidebar2();
  };
  const handleStartSession14 = () => {
    history.push("/doctor-app/profile");
    toggleSidebar();
    buttonSidebar2();
  };
  const handleStartSession15 = () => {
    history.push("/doctor-app/frontdesk_pushedto_doctor");
    toggleSidebar();
    buttonSidebar2();
  };
  const handleStartSession16 = () => {
    history.push("/doctor-app/Doctor_cancelled");
    toggleSidebar();
    buttonSidebar2();
  };
  const handleStartSession17 = () => {
    history.push("/doctor-app/Doctor_pending");
    toggleSidebar();
    buttonSidebar2();
  };
  const handleStartSession18 = () => {
    history.push("/doctor-app/working");
    toggleSidebar();
    buttonSidebar2();
  };
  const handleStartSession19 = () => {
    history.push("/doctor-app/frontdesk_show");
    toggleSidebar();
    buttonSidebar2();
  };

  const handleStartSession20 = () => {
    history.push("/doctor-app/frontdesk_Noshow");
    toggleSidebar();
    buttonSidebar2();
  };

  const handleStartSession21 = () => {
    history.push("/doctor-app/Today_Sheet");
    toggleSidebar();
    buttonSidebar2();
  };

  const handleStartSessiondoc = () => {
    history.push("/doctor-app/doctorinfo");
    toggleSidebar();
    buttonSidebar2();
  };

  return (
    <div>
      {/* {(isMenuBar &&( (isMatchingRoute ===false ||  isMatchingRoute2 ===false)) ) &&  (
        <button
         className="togglebutton"
          onClick={buttonSidebar2}
          style={{
            border: "none",
            position: "fixed",
            marginTop: "-30px",
            zIndex: "1001",
            marginLeft: "10px",
            backgroundColor: "white",
            borderRadius: "10px",
            height: "45px",
          }}
        >
          <i style={{ color: "black", backgroundColor: "white", fontSize: "30px", height: "20px", marginTop: "-20px" }}>
            <HiMiniBars3BottomLeft />
          </i>
        </button>
      )} */}
      {isMatchingRoute === false && (
        <div>
          {isTabletView && (
            <div>
              {(isMatchingRoute === false || isMatchingRoute2 === false) && userRole !== "FrontDesk" && userRole !== "Doctor" && (
                <button
                  onClick={toggleSidebar}
                  style={{ border: "none", marginTop: "130px", marginLeft: "20px", marginBottom: "70px", backgroundColor: "white", borderRadius: "10px" }}
                >
                  <i style={{ color: "#404040", backgroundColor: "white", fontSize: "30px" }}>
                    <HiMiniBars3BottomLeft />
                  </i>
                </button>
              )}
              {userRole === "Doctor" || userRole === "DoctorAssistant" || userRole === "AccountManager" ? (
                <div>
                  <div className="row">
                    {/* <button onClick={toggleSidebar}>Toggle Sidebar</button> */}
                    <div
                      className={`col-lg-3 col-sm-4 col-md-4 ${!isSidebarVisible ? "collapsed" : "showblock"} bar`}
                      style={{ position: "fixed", paddingTop: "82px", marginTop: "-115px", marginLeft: "-25px" }}
                    >
                      <div className="row navbar">
                        <div
                          className={`col-lg-10 col-md-12 col-sm-12 navbar1`}
                          style={{
                            backgroundColor: "#dddddd",
                            // marginTop: "-100px",
                            height: "100vh",
                            overflowY: "auto",
                            overflowWrap: "normal",
                            whiteSpace: "nowrap",
                            paddingBottom: "150px",
                            marginTop: "0",
                            marginRight: "50px",
                          }}
                        >
                          {isDesktop && (
                            <div className="row logo1" style={{ height: "200px", backgroundColor: "#dddddd", display: "block", marginTop: "-30px" }}>
                              <div style={{ marginLeft: "200px", paddingTop: "20px" }}></div>
                              <br />
                              <div style={{ textAlign: "center" }}>
                                {/* <img alt="Nyime App Logo" src={toAbsoluteUrl("/media/logo/xentrix.png")} style={{ width: "70px", height: "60px" }} /> */}
                                {/* <img alt="Nyime App Logo" src={main1} style={{ width: "140px", height: "100px", marginTop: "20px", marginRight: "-25px" }} /> */}
                              </div>

                              {/* {userRole==='Doctor' &&<div className=" ndoctor">
                              <b style={{ textAlign: "center", fontSize: "12px", color: "white" }}>KENNETH ALLEYNE</b>
                            </div>} */}
                            </div>
                          )}
                          <div className="row menubar">
                            <ul
                              style={{
                                marginTop: "0",
                                fontSize: "12px",
                                width: "100%",
                                backgroundColor: "#dddddd",
                                height: "300px",
                                marginLeft: "-20px",
                              }}
                              className="drop"
                            >
                              <div className="row logo1" style={{ height: "50px", backgroundColor: "#dddddd", display: "block", marginTop: "-30px" }}>
                                {isShow && (
                                  <div style={{ float: "right" }}>
                                    <button
                                      onClick={toggleSidebar}
                                      style={{ border: "none", borderRadius: "5px", backgroundColor: "#dddddd", marginTop: "-30px" }}
                                    >
                                      <i
                                        style={{
                                          color: "#404040",
                                          backgroundColor: "none",
                                          overflow: "hidden",
                                          fontSize: "30px",
                                          fontWeight: "bold",
                                          marginTop: "-30px",
                                        }}
                                      >
                                        <HiChevronDoubleLeft />
                                      </i>
                                    </button>
                                  </div>
                                )}
                              </div>
                              <li
                                style={{
                                  color: "#404040",
                                  fontWeight: "600",
                                  marginTop: "-40px",
                                }}
                              >
                                <i style={{ color: "#404040", paddingRight: "10px", width: "30px", fontSize: "20px" }}>
                                  <MdDashboard class="imagesize" />
                                </i>
                                DASHBOARD
                              </li>
                              {/* <li>
                                <i style={{ color: "#404040", paddingRight: "10px" }} class="fa-solid fa-layer-group"></i>DASHBOARD
                              </li> */}
                              &nbsp;
                              <li>
                                {userRole === "Doctor" && (
                                  <AnchorBtn style={{ color: "#404040", fontWeight: "600" }} href="" onClick={() => handleStartSessiondoc()}>
                                    <i style={{ color: "#404040", paddingRight: "10px" }}>
                                      {/* <img alt="img" src={Session} style={{ width: "30px" }} /> */}
                                      <RxLapTimer class="imagesize" />
                                    </i>{" "}
                                    START THE SESSION
                                  </AnchorBtn>
                                )}
                                {userRole === "DoctorAssistant" && (
                                  <AnchorBtn style={{ color: "#404040", fontWeight: "600" }} href="" onClick={() => handleStartSession()}>
                                    <i style={{ color: "#404040", paddingRight: "10px" }}>
                                      {/* <img alt="img" src={Session} style={{ width: "30px" }} /> */}
                                      <RxLapTimer class="imagesize" />
                                    </i>
                                    START THE SESSION
                                  </AnchorBtn>
                                )}
                                {userRole === "AccountManager" && (
                                  <AnchorBtn
                                    style={{ color: "#404040", fontWeight: "600" }}
                                    href=""
                                    onClick={() => history.push("/doctor-app/location_alotment")}
                                  >
                                    <i style={{ color: "#404040", paddingRight: "10px" }}>
                                      <img alt="img" src={Session} style={{ width: "30px" }} />
                                    </i>
                                    ASSIGN FRONT DESK
                                  </AnchorBtn>
                                )}
                              </li>
                              &nbsp;
                              <li
                                className={`drop-downitem1 ${isactive ? "active" : ""} text-container ${isClicked ? "clickedmenu" : ""}`}
                                onClick={() => {
                                  toggleDropdown1();
                                  handleClick();
                                }}
                              >
                                <AnchorBtn href="#" style={{ color: isactive ? "white" : "#404040", paddingTop: isactive ? "5px" : "0px", fontWeight: "600" }}>
                                  <i style={{ color: isactive ? "white" : "#404040", paddingRight: "10px" }}>
                                    {" "}
                                    {/* <img alt="img" src={Signin} style={{ width: "30px" }} />{" "} */}
                                    <SiGooglesheets class="imagesize" />
                                  </i>
                                  SIGN-IN-SHEET
                                </AnchorBtn>
                              </li>
                              <i
                                onClick={() => {
                                  toggleDropdown1();
                                  handleClick();
                                }}
                                style={{ color: isactive ? "white" : "#404040", float: "right", marginTop: "-27px", marginRight: "10px" }}
                              >
                                <FontAwesomeIcon icon={isactive ? faCaretUp : faCaretDown} />
                              </i>
                              {isactive && (
                                <ul
                                  className="ullist"
                                  style={{
                                    fontSize: "10px",
                                    // marginLeft: "-10px",
                                    // width: "100%",
                                    border: "1px solid #1B96BC",
                                    borderRadius: "7px",
                                    backgroundColor: "#d4e8ff",
                                    width: "230px",
                                    height: "auto",
                                    paddingBottom: "20px",
                                    marginTop: "5px",
                                    marginLeft: "1px",
                                  }}
                                >
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession1()}>
                                      STARTING OF THE DAY
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession2()}>
                                      ENDING OF THE DAY
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession3()}>
                                      OVERALL DAY
                                    </AnchorBtn>
                                  </li>
                                </ul>
                              )}
                              &nbsp;
                              <li
                                className={`drop-downitem ${isActive ? "active" : ""} text-container ${isClicked1 ? "clickedmenu" : ""}`}
                                onClick={() => {
                                  toggleDropdown();
                                  handleClick1();
                                }}
                              >
                                <AnchorBtn style={{ color: isActive ? "white" : "#404040", paddingTop: isActive ? "5px" : "0px", fontWeight: "600" }} href="#">
                                  <i style={{ color: isActive ? "white" : "#404040", paddingRight: "10px" }}>
                                    {/* <img alt="img" src={ToSession} style={{ width: "30px" }} /> */}
                                    <IoToday class="imagesize" />
                                  </i>
                                  TODAY'S SESSION
                                </AnchorBtn>
                              </li>
                              <i
                                onClick={() => {
                                  toggleDropdown();
                                  handleClick1();
                                }}
                                style={{ color: isActive ? "white" : "#404040", float: "right", marginTop: "-27px", marginRight: "10px" }}
                              >
                                <FontAwesomeIcon icon={isActive ? faCaretUp : faCaretDown} />
                              </i>
                              {userRole === "Doctor" && isActive && (
                                <ul
                                  className="ullist"
                                  style={{
                                    fontSize: "10px",
                                    // marginLeft: "-10px",
                                    // width: "100%",
                                    border: "1px solid #1B96BC",
                                    borderRadius: "7px",
                                    backgroundColor: "#d4e8ff",
                                    width: "230px",
                                    height: "auto",
                                    paddingBottom: "20px",
                                    marginTop: "5px",
                                    marginLeft: "1px",
                                  }}
                                >
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession15()}>
                                      FROM D.A
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession16()}>
                                      CANCELLED BY DOCTOR
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession17()}>
                                      Waiting by DOCTOR
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession18()}>
                                      WORKING BY DOCTOR
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession19()}>
                                      SHOW
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession20()}>
                                      NO SHOW
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession21()}>
                                      OVER ALL
                                    </AnchorBtn>
                                  </li>
                                </ul>
                              )}
                              {userRole === "DoctorAssistant" && isActive && (
                                <ul
                                  className="ullist"
                                  style={{
                                    fontSize: "10px",
                                    // marginLeft: "-10px",
                                    // width: "100%",
                                    border: "1px solid #1B96BC",
                                    borderRadius: "7px",
                                    backgroundColor: "#d4e8ff",
                                    width: "230px",
                                    height: "auto",
                                    paddingBottom: "20px",
                                    fontWeight: "bold",
                                    marginTop: "5px",
                                    marginLeft: "1px",
                                  }}
                                >
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession4()}>
                                      SCHEDULED
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession5()}>
                                      WORKING BY FRONT DESK
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession6()}>
                                      PUSHED TO DOCTOR
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession7()}>
                                      SHOW LIST
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession8()}>
                                      PENDING BY FRONT DESK
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession9()}>
                                      NO EXAM BY FRONT DESK
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession10()}>
                                      NO SHOW LIST
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession11()}>
                                      WAITING BY DOCTOR
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession12()}>
                                      CANCELLED BY DOCTOR
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession1()}>
                                      STARTING OF THE DAY
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession2()}>
                                      ENDING OF THE DAY
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn style={{ color: "#404040", float: "left" }} href="" onClick={() => handleStartSession3()}>
                                      OVERALL DAY
                                    </AnchorBtn>
                                  </li>
                                </ul>
                              )}
                              {userRole === "AccountManager" && isActive && (
                                <ul
                                  className="ullist"
                                  style={{
                                    fontSize: "10px",
                                    // marginLeft: "-10px",
                                    // width: "100%",
                                    border: "1px solid #1B96BC",
                                    borderRadius: "7px",
                                    backgroundColor: "#d4e8ff",
                                    width: "230px",
                                    height: "auto",
                                    paddingBottom: "20px",
                                    fontWeight: "bold",
                                    marginTop: "5px",
                                    marginLeft: "1px",
                                  }}
                                >
                                  &nbsp;
                                  <li>
                                    <AnchorBtn
                                      style={{ color: "#404040", float: "left" }}
                                      href=""
                                      onClick={() => {
                                        history.push("/doctor-app/from_frondesk");
                                        toggleSidebar();
                                      }}
                                    >
                                      SCHEDULED
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn
                                      style={{ color: "#404040", float: "left" }}
                                      href=""
                                      onClick={() => {
                                        history.push("/doctor-app/frontdesk_Working");
                                        toggleSidebar();
                                      }}
                                    >
                                      WORKING BY FRONTDESK
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn
                                      style={{ color: "#404040", float: "left" }}
                                      href=""
                                      onClick={() => {
                                        history.push("/doctor-app/frontdesk_pushedto_doctor");
                                        toggleSidebar();
                                      }}
                                    >
                                      WAITING FOR DOCTOR
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn
                                      style={{ color: "#404040", float: "left" }}
                                      href=""
                                      onClick={() => {
                                        history.push("/doctor-app/working");
                                        toggleSidebar();
                                      }}
                                    >
                                      WORKING BY DOCTOR
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn
                                      style={{ color: "#404040", float: "left" }}
                                      href=""
                                      onClick={() => {
                                        history.push("/doctor-app/examined");
                                        toggleSidebar();
                                      }}
                                    >
                                      EXAMINED
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn
                                      style={{ color: "#404040", float: "left" }}
                                      href=""
                                      onClick={() => {
                                        history.push("/doctor-app/overall_pending");
                                        toggleSidebar();
                                      }}
                                    >
                                      PENDING
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn
                                      style={{ color: "#404040", float: "left" }}
                                      href=""
                                      onClick={() => {
                                        history.push("/doctor-app/overall_cancelled");
                                        toggleSidebar();
                                      }}
                                    >
                                      CANCELLED
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn
                                      style={{ color: "#404040", float: "left" }}
                                      href=""
                                      onClick={() => {
                                        history.push("/doctor-app/frontdesk_Show");
                                        toggleSidebar();
                                      }}
                                    >
                                      SHOW LIST
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn
                                      style={{ color: "#404040", float: "left" }}
                                      href=""
                                      onClick={() => {
                                        history.push("/doctor-app/frontdesk_Noshow");
                                        toggleSidebar();
                                      }}
                                    >
                                      NO SHOW LIST
                                    </AnchorBtn>
                                  </li>
                                  &nbsp;
                                  <li>
                                    <AnchorBtn
                                      style={{ color: "#404040", float: "left" }}
                                      href=""
                                      onClick={() => {
                                        history.push("/doctor-app/Today_Sheet");
                                        toggleSidebar();
                                      }}
                                    >
                                      OVERALL SHEET
                                    </AnchorBtn>
                                  </li>
                                </ul>
                              )}
                              &nbsp;
                              <li
                                className={`drop-downitem ${isactive1 ? "active" : ""} text-container ${isClicked2 ? "clickedmenu" : ""}`}
                                onClick={() => {
                                  toggleDropdown2();
                                  handleClick2();
                                }}
                              >
                                <AnchorBtn style={{ color: isactive1 ? "white" : "#404040", paddingTop: isActive ? "5px" : "0px", fontWeight: "600" }}>
                                  <i style={{ color: isactive1 ? "white" : "#404040", paddingRight: "10px" }}>
                                    {/* <img alt="img" src={Resource} style={{ width: "30px" }} /> */}
                                    <IoIosFolder class="imagesize" />
                                  </i>
                                  RESOURCES
                                </AnchorBtn>
                              </li>
                              <i
                                onClick={() => {
                                  toggleDropdown2();
                                  handleClick2();
                                }}
                                style={{ color: isactive1 ? "white" : "#404040", float: "right", marginTop: "-27px", marginRight: "10px" }}
                              >
                                <FontAwesomeIcon icon={isactive1 ? faCaretUp : faCaretDown} />
                              </i>
                              {isactive1 && (
                                <ul
                                  className="ullist"
                                  style={{
                                    fontSize: "10px",
                                    // marginLeft: "-10px",
                                    // width: "100%",
                                    border: "1px solid #1B96BC",
                                    borderRadius: "7px",
                                    backgroundColor: "#d4e8ff",
                                    width: "230px",
                                    height: "auto",
                                    paddingBottom: "20px",
                                    marginTop: "5px",
                                    marginLeft: "1px",
                                  }}
                                >
                                  &nbsp;
                                  <li className={`drop-downitem ${isactive2 ? "active" : ""}`} onClick={toggleDropdown3}>
                                    <AnchorBtn
                                      style={{ color: "#404040", float: "left", fontWeight: "600" }}
                                      // href={Impairment}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      IMPAIRMENT GUIDELINES
                                    </AnchorBtn>
                                  </li>
                                  <i onClick={toggleDropdown3} style={{ color: "#404040", float: "right", marginTop: "-20px", marginRight: "10px" }}>
                                    <FontAwesomeIcon icon={isactive2 ? faCaretUp : faCaretDown} />
                                  </i>
                                  &nbsp;
                                  {isactive2 && (
                                    <ul
                                      style={{
                                        border: "none",
                                        backgroundColor: "white",
                                        height: "50px",
                                        width: "190px",
                                        display: "flow",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "5px",
                                        marginLeft: "-30px",
                                        textAlign: "center",
                                      }}
                                    >
                                      <li>
                                        <RiCheckboxBlankFill style={{ width: "5px", float: "left", marginTop: "9px" }} />
                                        <AnchorBtn
                                          style={{ color: "#404040", float: "left", marginTop: "8px", fontWeight: "600", marginLeft: "10px" }}
                                          // href={SLU}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          SPINE-PERMANENCY
                                        </AnchorBtn>
                                      </li>{" "}
                                      <br />
                                      &nbsp;
                                      <li>
                                        <RiCheckboxBlankFill style={{ width: "5px", float: "left", marginTop: "2px" }} />
                                        <AnchorBtn
                                          style={{ color: "#404040", float: "left", fontWeight: "600", marginLeft: "10px" }}
                                          // href={AnkleInjury}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          EXTREMITIES-SLU
                                        </AnchorBtn>
                                      </li>
                                    </ul>
                                  )}
                                  &nbsp;
                                  <li onClick={toggleDropdown4} className={`drop-downitem ${isactive3 ? "active" : ""}`}>
                                    <AnchorBtn
                                      style={{ color: "#404040", float: "left", fontWeight: "600" }}
                                      // href={HandInjury}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      TREATMENT GUIDELINES
                                    </AnchorBtn>
                                  </li>
                                  <i onClick={toggleDropdown4} style={{ color: "#404040", float: "right", marginTop: "-20px", marginRight: "10px" }}>
                                    <FontAwesomeIcon icon={isactive3 ? faCaretUp : faCaretDown} />
                                  </i>
                                  &nbsp;
                                  {isactive3 && (
                                    <ul
                                      style={{
                                        border: "none",
                                        backgroundColor: "white",
                                        height: "180px",
                                        width: "190px",
                                        display: "flow",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginLeft: "-30px",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <li>
                                        <RiCheckboxBlankFill style={{ width: "5px", float: "left", marginTop: "7px" }} />
                                        <AnchorBtn
                                          style={{
                                            color: "#404040",
                                            float: "left",
                                            paddingTop: "5px",
                                            fontWeight: "600",
                                            display: "flex",
                                            alignItems: "center",
                                            marginLeft: "10px",
                                          }}
                                          // href={HipInjury}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          NECK
                                        </AnchorBtn>
                                      </li>
                                      <br />
                                      &nbsp;
                                      <li>
                                        <RiCheckboxBlankFill style={{ width: "5px", float: "left", marginTop: "-2px" }} />
                                        <AnchorBtn
                                          style={{ color: "#404040", float: "left", marginTop: "-5px", fontWeight: "600", display: "flex", marginLeft: "10px" }}
                                          // href={HipInjury}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          MID & LOWBACK
                                        </AnchorBtn>
                                      </li>
                                      <br />
                                      &nbsp;
                                      <li>
                                        <RiCheckboxBlankFill style={{ width: "5px", float: "left", marginTop: "-8px" }} />
                                        <AnchorBtn
                                          style={{
                                            color: "#404040",
                                            float: "left",
                                            marginTop: "-10px",
                                            fontWeight: "600",
                                            display: "flex",
                                            alignItems: "center",
                                            marginLeft: "10px",
                                          }}
                                          // href={HipInjury}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          SHOULDER
                                        </AnchorBtn>
                                      </li>
                                      <br />
                                      &nbsp;
                                      <li>
                                        <RiCheckboxBlankFill style={{ width: "5px", float: "left", marginTop: "-13px" }} />
                                        <AnchorBtn
                                          style={{
                                            color: "#404040",
                                            float: "left",
                                            marginTop: "-15px",
                                            fontWeight: "600",
                                            display: "flex",
                                            alignItems: "center",
                                            marginLeft: "15px",
                                          }}
                                          // href={HipInjury}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          HIP
                                        </AnchorBtn>
                                      </li>
                                      <br />
                                      &nbsp;
                                      <li>
                                        <RiCheckboxBlankFill style={{ width: "5px", float: "left", marginTop: "-18px" }} />
                                        <AnchorBtn
                                          style={{
                                            color: "#404040",
                                            float: "left",
                                            marginTop: "-20px",
                                            fontWeight: "600",
                                            display: "flex",
                                            alignItems: "center",
                                            marginLeft: "15px",
                                          }}
                                          // href={HipInjury}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          KNEE
                                        </AnchorBtn>
                                      </li>
                                      <br />
                                      &nbsp;
                                      <li>
                                        <RiCheckboxBlankFill style={{ width: "5px", float: "left", marginTop: "-22px" }} />
                                        <AnchorBtn
                                          style={{
                                            color: "#404040",
                                            float: "left",
                                            marginTop: "-25px",
                                            fontWeight: "600",
                                            display: "flex",
                                            alignItems: "center",
                                            marginLeft: "15px",
                                          }}
                                          // href={HipInjury}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          HAND & WRIST
                                        </AnchorBtn>
                                      </li>
                                      <br />
                                      &nbsp;
                                      <li>
                                        <RiCheckboxBlankFill style={{ width: "5px", float: "left", marginTop: "-25px" }} />
                                        <AnchorBtn
                                          style={{
                                            color: "#404040",
                                            float: "left",
                                            marginTop: "-30px",
                                            fontWeight: "600",
                                            display: "flex",
                                            alignItems: "center",
                                            marginLeft: "15px",
                                          }}
                                          // href={HipInjury}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          ANKLE & FOOT
                                        </AnchorBtn>
                                      </li>
                                      &nbsp;
                                    </ul>
                                  )}
                                </ul>
                              )}
                              &nbsp;
                              <li style={{ fontWeight: "600" }}>
                                <i style={{ color: "#404040", paddingRight: "10px" }}>
                                  {" "}
                                  {/* <img alt="img" src={search} style={{ width: "30px" }} /> */}
                                  <ImSearch class="imagesize" />
                                </i>
                                <AnchorBtn style={{ color: "#404040", fontWeight: "600" }} href="" onClick={() => handleStartSession13()}>
                                  SEARCH
                                </AnchorBtn>
                              </li>
                              &nbsp;
                              <li style={{ fontWeight: "600" }}>
                                <i style={{ color: "#404040", paddingRight: "10px" }}>
                                  {" "}
                                  {/* <img alt="img" src={Profile} style={{ width: "30px" }} /> */}
                                  <FaCircleUser class="imagesize" />
                                </i>
                                <AnchorBtn style={{ color: "#404040", fontWeight: "600" }} href="" onClick={() => handleStartSession14()}>
                                  PROFILE
                                </AnchorBtn>
                              </li>
                              &nbsp;
                              <li style={{ color: "#404040", fontWeight: "600" }}>
                                <i style={{ color: "#404040", paddingRight: "10px" }}>
                                  {" "}
                                  {/* <img alt="img" src={Site} style={{ width: "30px" }} /> */}
                                  <BiSolidHelpCircle class="imagesize" />
                                </i>
                                <AnchorBtn style={{ color: "#404040", fontWeight: "600" }} href="" onClick={() => history.push("/doctor-app/sitehelp")}>
                                  SITE HELP
                                </AnchorBtn>
                              </li>
                              &nbsp;
                              <li style={{ color: "#404040", fontWeight: "600" }}>
                                <i style={{ color: "#404040", paddingRight: "10px" }}>
                                  {/* <img alt="img" src={Contact} style={{ width: "30px" }} /> */}
                                  <MdContactPhone class="imagesize" />
                                </i>
                                CONTACT
                              </li>
                              &nbsp;
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
